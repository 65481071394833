<template>
  <div class="home">
    <qilv-header></qilv-header>
    <div class="content">
      <div class="slogan">骑驴，探索奇妙世界</div>
      <div style="">
        <div class="scan-without-code"
             style="height: 200px;padding-top: 40px;"
             :style="{display: iOSCodeVisibility?'':'none'}">
          <img :src="require('@/assets/images/code/download-code.png')"
               style="width: 140px;" fit="contain"/>
        </div>
        <div class="scan-without-code" @mouseenter="showIOSCode" @mouseleave="hideIOSCode">
          <img :src="require('@/assets/images/code/download-icon.png')"
               style="width: 30px; margin-right: 10px" fit="contain"/>
          下载
        </div>
      </div>
    </div>
    <qilv-footer></qilv-footer>
    <video-slider></video-slider>
  </div>
</template>

<script>
import VideoSlider from "../components/VideoSlider";

export default {
  name: "Home",
  components: {VideoSlider},
  data() {
    return {
      iOSCodeVisibility: false,
      androidCodeVisibility: false,
    }
  },
  methods: {
    showIOSCode() {
      this.iOSCodeVisibility = true;
    },
    hideIOSCode() {
      this.iOSCodeVisibility = false;
    },
    showAndroidCode() {
      this.androidCodeVisibility = true;
    },
    hideAndroidCode() {
      this.androidCodeVisibility = false;
    }
  }
}
</script>

<style scoped lang="less">
.home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.slogan {
  top: 330px;
  position: absolute;
  margin-right: 100px;
  width: 312px;
  color: white;
  font-size: 30px;
}

.scan-without-code {
  top: 540px;
  cursor: pointer;
  position: absolute;
  width: 180px;
  height: 50px;
  margin-right: 100px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #323232;
}
</style>